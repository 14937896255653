





















import IFCViewer from "@/components/IFCViewer.vue";
import IFCNetStatistics from "@/components/IFCNetStatistics.vue";
import KeyBindingManager from "@/components/KeyBindingManager.vue";
import { api } from "@/api";
import { Vue, Component } from "vue-property-decorator";
import { KeyBinding } from "@/interfaces";

@Component({
  components: {
    IFCViewer,
    IFCNetStatistics,
    KeyBindingManager
  }
})
export default class Contribute extends Vue {
  mappableIfcClasses: Array<string> = [];
  confirmedModelCounts: Record<string, number> = {};
  keys: Array<KeyBinding> = [
    { key: "1", type: "" },
    { key: "2", type: "" },
    { key: "3", type: "" },
    { key: "4", type: "" },
    { key: "5", type: "" },
    { key: "6", type: "" },
    { key: "7", type: "" },
    { key: "8", type: "" },
    { key: "9", type: "" },
    { key: "0", type: "" }
  ];

  mounted() {
    this.getMappableClasses();
    this.updateStatistics();
  }

  async getMappableClasses() {
    const response = await api.getMappableClasses();
    if (response.data) {
      this.mappableIfcClasses = response.data;
    }
  }
  async updateStatistics() {
    const response = await api.getModelClasses({
      confirmed: true
    });
    if (response.data) {
      this.confirmedModelCounts = response.data;
    }
  }
}
