




























import { Vue, Component, Prop } from "vue-property-decorator";
import { KeyBinding } from "@/interfaces";

@Component
export default class KeyBindingManager extends Vue {
  @Prop({ required: true }) ifcClasses!: Array<string>;
  @Prop({ required: true }) keys!: Array<KeyBinding>;
}
