

























import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IFCNetStatistics extends Vue {
  @Prop({ required: true }) confirmedModelCounts!: Record<string, number>;
}
